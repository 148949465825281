import React, { Suspense } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./Routes/PrivateRoute";
import HomePage from "./Component/Views/HomePage";
// const { Login, Dashboard, Customer } = React.lazy(() => import("./Screens"));
const Login = React.lazy(() => import("./Screens/Login"));
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const ForgotPassword = React.lazy(() =>
  import("./Screens/ForgotPassword/ForgotSendMail")
);

function App() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Switch>
        <Route exact path="/login" render={(props) => <Login {...props} />} />
        <Route
          exact
          path="/forgotPassword"
          render={(props) => <ForgotPassword {...props} />}
        />
        {/* <HomePage /> */}
        <PrivateRoute
          path="/"
          component={DefaultLayout}
        // render={(props) => <DefaultLayout {...props} />}
        />
      </Switch>
    </Suspense>
  );
}

export default App;
