import {
  ADMIN_FORGET_PASSWORD_REQUEST,
  ADMIN_FORGET_PASSWORD_SUCCESS,
  ADMIN_FORGET_PASSWORD_FAIL,
  ADMIN_FORGOT_OTP_REQUEST,
  ADMIN_FORGOT_OTP_SUCCESS,
  ADMIN_FORGOT_OTP_FAIL,
  ADMIN_CONFIRM_PASSWORD_REQUEST,
  ADMIN_CONFIRM_PASSWORD_SUCCESS,
  ADMIN_CONFIRM_PASSWORD_FAIL,
} from "../Types";

export const AdminForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_FORGET_PASSWORD_REQUEST:
      return { loading: true };
    case ADMIN_FORGET_PASSWORD_SUCCESS:
      return { loading: false, forgotPassword: action.payload };
    case ADMIN_FORGET_PASSWORD_FAIL:
      return { loading: false, forgotPassword: action.payload };
    default:
      return state;
  }
};

export const AdminForgotOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_FORGOT_OTP_REQUEST:
      return { loading: true };
    case ADMIN_FORGOT_OTP_SUCCESS:
      return { loading: false, forgotOtp: action.payload };
    case ADMIN_FORGOT_OTP_FAIL:
      return { loading: false, forgotOtp: action.payload };
    default:
      return state;
  }
};

export const AdminConfirmPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CONFIRM_PASSWORD_REQUEST:
      return { loading: true };
    case ADMIN_CONFIRM_PASSWORD_SUCCESS:
      return { loading: false, confirmPassword: action.payload };
    case ADMIN_CONFIRM_PASSWORD_FAIL:
      return { loading: false, confirmPassword: action.payload };
    default:
      return state;
  }
};
