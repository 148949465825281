import {
  ADMIN_GET_PARENTS_REQUEST,
  ADMIN_GET_PARENTS_SUCCESS,
  ADMIN_GET_PARENTS_FAIL,
  ADMIN_DOCUMENT_VERIFY_REQUEST,
  ADMIN_DOCUMENT_VERIFY_SUCCESS,
  ADMIN_DOCUMENT_VERIFY_FAIL,
  ADMIN_DOCUMENT_VERIFY_REMOVE,
} from "../Types";

export const AdminParentsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_PARENTS_REQUEST:
      return { loading: true };
    case ADMIN_GET_PARENTS_SUCCESS:
      return { loading: false, allParents: action.payload };
    case ADMIN_GET_PARENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const AdminDocumentVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DOCUMENT_VERIFY_REQUEST:
      return { loading: true };
    case ADMIN_DOCUMENT_VERIFY_SUCCESS:
      return { loading: false, documentVerify: action.payload };
    case ADMIN_DOCUMENT_VERIFY_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_DOCUMENT_VERIFY_REMOVE:
      return { loading: false, documentRemove: action.payload };
    default:
      return state;
  }
};
