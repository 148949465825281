// admin login
export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

// get profile details
export const ADMIN_GET_PROFILE_REQUEST = "ADMIN_GET_PROFILE_REQUEST";
export const ADMIN_GET_PROFILE_SUCCESS = "ADMIN_GET_PROFILE_SUCCESS";
export const ADMIN_GET_PROFILE_FAIL = "ADMIN_GET_PROFILE_FAIL";

// update profile
export const ADMIN_UPDATE_PROFILE_REQUEST = "ADMIN_UPDATE_PROFILE_REQUEST";
export const ADMIN_UPDATE_PROFILE_SUCCESS = "ADMIN_UPDATE_PROFILE_SUCCESS";
export const ADMIN_UPDATE_PROFILE_FAIL = "ADMIN_UPDATE_PROFILE_FAIL";
export const ADMIN_UPLOADIMAGE_REQUEST = "ADMIN_UPLOADIMAGE_REQUEST";
export const ADMIN_UPLOADIMAGE_SUCCESS = "ADMIN_UPLOADIMAGE_SUCCESS";
export const ADMIN_UPLOADIMAGE_FAIL = "ADMIN_UPLOADIMAGE_FAIL";
export const ADMIN_UPLOADIMAGE_RESPONSE = "ADMIN_UPLOADIMAGE_RESPONSE";

// Admin Stats
export const ADMIN_STATS_REQUEST = "ADMIN_STATS_REQUEST";
export const ADMIN_STATS_SUCCESS = "ADMIN_STATS_SUCCESS";
export const ADMIN_STATS_FAIL = "ADMIN_STATS_FAIL";

// Admin Lessons & Earnings by sports
export const ADMIN_LESSONS_BY_SPORTS_REQUEST =
  "ADMIN_LESSONS_BY_SPORTS_REQUEST";
export const ADMIN_LESSONS_BY_SPORTS_SUCCESS =
  "ADMIN_LESSONS_BY_SPORTS_SUCCESS";
export const ADMIN_LESSONS_BY_SPORTS_FAIL = "ADMIN_LESSONS_BY_SPORTS_FAIL";
export const ADMIN_EARNING_BY_SPORTS_REQUEST =
  "ADMIN_EARNING_BY_SPORTS_REQUEST";
export const ADMIN_EARNING_BY_SPORTS_SUCCESS =
  "ADMIN_EARNING_BY_SPORTS_SUCCESS";
export const ADMIN_EARNING_BY_SPORTS_FAIL = "ADMIN_EARNING_BY_SPORTS_FAIL";

// Students details
export const ADMIN_GET_STUDENTS_REQUEST = "ADMIN_GET_STUDENTS_REQUEST";
export const ADMIN_GET_STUDENTS_SUCCESS = "ADMIN_GET_STUDENTS_SUCCESS";
export const ADMIN_GET_STUDENTS_FAIL = "ADMIN_GET_STUDENTS_FAIL";

// Parents details
export const ADMIN_GET_PARENTS_REQUEST = "ADMIN_GET_PARENTS_REQUEST";
export const ADMIN_GET_PARENTS_SUCCESS = "ADMIN_GET_PARENTS_SUCCESS";
export const ADMIN_GET_PARENTS_FAIL = "ADMIN_GET_PARENTS_FAIL";

// Coach details
export const ADMIN_GET_COACHES_REQUEST = "ADMIN_GET_COACHES_REQUEST";
export const ADMIN_GET_COACHES_SUCCESS = "ADMIN_GET_COACHES_SUCCESS";
export const ADMIN_GET_COACHES_FAIL = "ADMIN_GET_COACHES_FAIL";
export const ADMIN_GET_COACH_HEADER_REQUEST = "ADMIN_GET_COACH_HEADER_REQUEST";
export const ADMIN_GET_COACH_HEADER_SUCCESS = "ADMIN_GET_COACH_HEADER_SUCCESS";
export const ADMIN_GET_COACH_HEADER_FAIL = "ADMIN_GET_COACH_HEADER_FAIL";

export const ADMIN_COACH_BOOKING_LIST_REQUEST =
  "ADMIN_COACH_BOOKING_LIST_REQUEST";
export const ADMIN_COACH_BOOKING_LIST_SUCCESS =
  "ADMIN_COACH_BOOKING_LIST_SUCCESS";
export const ADMIN_COACH_BOOKING_LIST_FAIL = "ADMIN_COACH_BOOKING_LIST_FAIL";

// forgot password
export const ADMIN_FORGET_PASSWORD_REQUEST = "ADMIN_FORGET_PASSWORD_REQUEST";
export const ADMIN_FORGET_PASSWORD_SUCCESS = "ADMIN_FORGET_PASSWORD_SUCCESS";
export const ADMIN_FORGET_PASSWORD_FAIL = "ADMIN_FORGET_PASSWORD_FAIL";

export const ADMIN_FORGOT_OTP_REQUEST = "ADMIN_FORGOT_OTP_REQUEST";
export const ADMIN_FORGOT_OTP_SUCCESS = "ADMIN_FORGOT_OTP_SUCCESS";
export const ADMIN_FORGOT_OTP_FAIL = "ADMIN_FORGOT_OTP_FAIL";

export const ADMIN_CONFIRM_PASSWORD_REQUEST = "ADMIN_CONFIRM_PASSWORD_REQUEST";
export const ADMIN_CONFIRM_PASSWORD_SUCCESS = "ADMIN_CONFIRM_PASSWORD_SUCCESS";
export const ADMIN_CONFIRM_PASSWORD_FAIL = "ADMIN_CONFIRM_PASSWORD_FAIL";

// Admin Booking
export const ADMIN_GET_BOOKING_REQUEST = "ADMIN_GET_BOOKING_REQUEST";
export const ADMIN_GET_BOOKING_SUCCESS = "ADMIN_GET_BOOKING_SUCCESS";
export const ADMIN_GET_BOOKING_FAIL = "ADMIN_GET_BOOKING_FAIL";
export const ADMIN_GET_BOOKING_DETAIL_REQUEST =
  "ADMIN_GET_BOOKING_DETAIL_REQUEST";
export const ADMIN_GET_BOOKING_DETAIL_SUCCESS =
  "ADMIN_GET_BOOKING_DETAIL_SUCCESS";
export const ADMIN_GET_BOOKING_DETAIL_FAIL = "ADMIN_GET_BOOKING_DETAIL_FAIL";

//Admin sports
export const ADMIN_CREATE_SPORTS_REQUEST = "ADMIN_CREATE_SPORTS_REQUEST ";
export const ADMIN_CREATE_SPORTS_SUCCESS = "ADMIN_CREATE_SPORTS_SUCCESS";
export const ADMIN_CREATE_SPORTS_FAIL = "ADMIN_CREATE_SPORTS_FAIL";
export const ADMIN_SPORTS_TYPE_UPLOADIMAGE_REQUEST =
  "ADMIN_SPORTS_TYPE_UPLOADIMAGE_REQUEST";
export const ADMIN_SPORTS_TYPE_UPLOADIMAGE_SUCCESS =
  "ADMIN_SPORTS_TYPE_UPLOADIMAGE_SUCCESS";
export const ADMIN_SPORTS_TYPE_UPLOADIMAGE_FAIL =
  "ADMIN_SPORTS_TYPE_UPLOADIMAGE_FAIL";
export const ADMIN_SPORTS_TYPE_UPLOADIMAGE_RESPONSE =
  "ADMIN_SPORTS_TYPE_UPLOADIMAGE_RESPONSE";
export const ADMIN_REMOVE_SPORTS_REQUEST = "ADMIN_REMOVE_SPORTS_REQUEST ";
export const ADMIN_REMOVE_SPORTS_SUCCESS = "ADMIN_REMOVE_SPORTS_SUCCESS";
export const ADMIN_REMOVE_SPORTS_FAIL = "ADMIN_REMOVE_SPORTS_FAIL";
export const ADMIN_UPDATE_SPORTS_REQUEST = "ADMIN_UPDATE_SPORTS_REQUEST ";
export const ADMIN_UPDATE_SPORTS_SUCCESS = "ADMIN_UPDATE_SPORTS_SUCCESS";
export const ADMIN_UPDATE_SPORTS_FAIL = "ADMIN_UPDATE_SPORTS_FAIL";

export const ADMIN_GET_SPORTS_REQUEST = "ADMIN_GET_SPORTS_REQUEST";
export const ADMIN_GET_SPORTS_SUCCESS = "ADMIN_GET_SPORTS_SUCCESS";
export const ADMIN_GET_SPORTS_FAIL = "ADMIN_GET_SPORTS_FAIL";
export const ADMIN_GET_SPORTS_BY_ID_REQUEST = "ADMIN_GET_SPORTS_BY_ID_REQUEST";
export const ADMIN_GET_SPORTS_BY_ID_SUCCESS = "ADMIN_GET_SPORTS_BY_ID_SUCCESS";
export const ADMIN_GET_SPORTS_BY_ID_FAIL = "ADMIN_GET_SPORTS_BY_ID_FAIL";

// Get VANUE List details
export const ADMIN_GET_VANUE_REQUEST = "ADMIN_GET_VANUE_REQUEST";
export const ADMIN_GET_VANUE_SUCCESS = "ADMIN_GET_VANUE_SUCCESS";
export const ADMIN_GET_VANUE_FAIL = "ADMIN_GET_VANUE_FAIL";
export const ADMIN_REMOVE_VANUE_REQUEST = "ADMIN_REMOVE_VANUE_REQUEST";
export const ADMIN_REMOVE_VANUE_SUCCESS = "ADMIN_REMOVE_VANUE_SUCCESS";
export const ADMIN_REMOVE_VANUE_FAIL = "ADMIN_REMOVE_VANUE_FAIL";
export const ADMIN_CREATE_VANUE_REQUEST = "ADMIN_CREATE_VANUE_REQUEST";
export const ADMIN_CREATE_VANUE_SUCCESS = "ADMIN_CREATE_VANUE_SUCCESS";
export const ADMIN_CREATE_VANUE_FAIL = "ADMIN_CREATE_VANUE_FAIL";
export const ADMIN_GET_VANUE_BY_ID_REQUEST = "ADMIN_GET_VANUE_BY_ID_REQUEST";
export const ADMIN_GET_VANUE_BY_ID_SUCCESS = "ADMIN_GET_VANUE_BY_ID_SUCCESS";
export const ADMIN_GET_VANUE_BY_ID_FAIL = "ADMIN_GET_VANUE_BY_ID_FAIL";
export const ADMIN_UPDATE_VANUE_REQUEST = "ADMIN_UPDATE_VANUE_REQUEST";
export const ADMIN_UPDATE_VANUE_SUCCESS = "ADMIN_UPDATE_VANUE_SUCCESS";
export const ADMIN_UPDATE_VANUE_FAIL = "ADMIN_UPDATE_VANUE_FAIL";
export const ADMIN_VANUE_TYPE_UPLOADIMAGE_REQUEST =
  "ADMIN_VANUE_TYPE_UPLOADIMAGE_REQUEST";
export const ADMIN_VANUE_TYPE_UPLOADIMAGE_SUCCESS =
  "ADMIN_VANUE_TYPE_UPLOADIMAGE_SUCCESS";
export const ADMIN_VANUE_TYPE_UPLOADIMAGE_FAIL =
  "ADMIN_VANUE_TYPE_UPLOADIMAGE_FAIL";
export const ADMIN_VANUE_TYPE_UPLOADIMAGE_RESPONSE =
  "ADMIN_VANUE_TYPE_UPLOADIMAGE_RESPONSE";

export const ADMIN_DOCUMENT_VERIFY_REQUEST = "ADMIN_DOCUMENT_VERIFY_REQUEST";
export const ADMIN_DOCUMENT_VERIFY_SUCCESS = "ADMIN_DOCUMENT_VERIFY_SUCCESS";
export const ADMIN_DOCUMENT_VERIFY_FAIL = "ADMIN_DOCUMENT_VERIFY_FAIL";
export const ADMIN_DOCUMENT_VERIFY_REMOVE = "ADMIN_DOCUMENT_VERIFY_REMOVE";

//PayOut
export const ADMIN_PAYOUT_REQUEST = "ADMIN_PAYOUT_REQUEST";
export const ADMIN_PAYOUT_SUCCESS = "ADMIN_PAYOUT_SUCCESS";
export const ADMIN_PAYOUT_FAIL = "ADMIN_PAYOUT_FAIL";

//Flag User
export const ADMIN_FLAG_USER_REQUEST = "ADMIN_FLAG_USER_REQUEST";
export const ADMIN_FLAG_USER_SUCCESS = "ADMIN_FLAG_USER_SUCCESS";
export const ADMIN_FLAG_USER_FAIL = "ADMIN_FLAG_USER_FAIL";
export const ADMIN_CLEAR_FLAG_REQUEST = "ADMIN_CLEAR_FLAG_REQUEST";
export const ADMIN_CLEAR_FLAG_SUCCESS = "ADMIN_CLEAR_FLAG_SUCCESS";
export const ADMIN_CLEAR_FLAG_FAIL = "ADMIN_CLEAR_FLAG_FAIL";

//Content Manage
export const ADMIN_GET_CONTENT_REQUEST = "ADMIN_GET_CONTENT_REQUEST";
export const ADMIN_GET_CONTENT_SUCCESS = "ADMIN_GET_CONTENT_SUCCESS";
export const ADMIN_GET_CONTENT_FAIL = "ADMIN_GET_CONTENT_FAIL";
export const ADMIN_UPDATE_CONTENT_REQUEST = "ADMIN_UPDATE_CONTENT_REQUEST";
export const ADMIN_UPDATE_CONTENT_SUCCESS = "ADMIN_UPDATE_CONTENT_SUCCESS";
export const ADMIN_UPDATE_CONTENT_FAIL = "ADMIN_UPDATE_CONTENT_FAIL";
