import {
  ADMIN_STATS_REQUEST,
  ADMIN_STATS_SUCCESS,
  ADMIN_STATS_FAIL,
  ADMIN_LESSONS_BY_SPORTS_REQUEST,
  ADMIN_LESSONS_BY_SPORTS_SUCCESS,
  ADMIN_LESSONS_BY_SPORTS_FAIL,
  ADMIN_EARNING_BY_SPORTS_REQUEST,
  ADMIN_EARNING_BY_SPORTS_SUCCESS,
  ADMIN_EARNING_BY_SPORTS_FAIL,
} from "../Types";

export const AdminStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LESSONS_BY_SPORTS_REQUEST:
      return { loading: true };
    case ADMIN_STATS_SUCCESS:
      return { loading: false, allStats: action.payload };
    case ADMIN_STATS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const LessonsBySportsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LESSONS_BY_SPORTS_REQUEST:
      return { loading: true };
    case ADMIN_LESSONS_BY_SPORTS_SUCCESS:
      return { loading: false, lessonsBySports: action.payload };
    case ADMIN_LESSONS_BY_SPORTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const EarningBySportsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_EARNING_BY_SPORTS_REQUEST:
      return { loading: true };
    case ADMIN_EARNING_BY_SPORTS_SUCCESS:
      return { loading: false, earningBySports: action.payload };
    case ADMIN_EARNING_BY_SPORTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
