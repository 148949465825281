import {
  ADMIN_GET_COACHES_REQUEST,
  ADMIN_GET_COACHES_SUCCESS,
  ADMIN_GET_COACHES_FAIL,
  ADMIN_GET_COACH_HEADER_REQUEST,
  ADMIN_GET_COACH_HEADER_SUCCESS,
  ADMIN_GET_COACH_HEADER_FAIL,
  ADMIN_COACH_BOOKING_LIST_REQUEST,
  ADMIN_COACH_BOOKING_LIST_SUCCESS,
  ADMIN_COACH_BOOKING_LIST_FAIL,
} from "../Types";

export const AdminCoachesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_COACHES_REQUEST:
      return { loading: true };
    case ADMIN_GET_COACHES_SUCCESS:
      return { loading: false, allCoaches: action.payload };
    case ADMIN_GET_COACHES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const AdminCoachHeaderReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_COACH_HEADER_REQUEST:
      return { loading: true };
    case ADMIN_GET_COACH_HEADER_SUCCESS:
      return { loading: false, coachHeader: action.payload };
    case ADMIN_GET_COACH_HEADER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const AdminCoachBookingListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_COACH_BOOKING_LIST_REQUEST:
      return { loading: true };
    case ADMIN_COACH_BOOKING_LIST_SUCCESS:
      return { loading: false, coachBookingsByid: action.payload };
    case ADMIN_COACH_BOOKING_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
