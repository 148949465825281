import React from 'react'
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import ShareIcon from '@mui/icons-material/Share';
import {
    autocompleteClasses,
    Avatar,
    Button,
    Grid,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@mui/material";

const HomePage = () => {
    //     const [order, setOrder] = useState("asc");
    //   const [orderBy, setOrderBy] = useState("calories");
    // const [rows, setRows] = useState(listedData);
    const [rowsData, setRowsData] = useState([]);
    const headCells = [
        { id: "sr", label: "Sr", minWidth: "auto", align: "left" },
        { id: "name", label: "Name", minWidth: "auto", align: "left" },
        { id: "flag", label: "Flag", minWidth: "auto", align: "left" },
        { id: "action`", label: "Action", minWidth: "auto", align: "left" },

    ]
    const addRow = () => {
        const rowsInput = {
            Name: '',
            flag: '',

        }
        setRowsData([...rowsData, rowsInput])
    }
    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }

    // const handleRequestSort = (event, property) => {
    //     const isAsc = orderBy === property && order === "asc";
    //     setOrder(isAsc ? "desc" : "asc");
    //     setOrderBy(property);
    //   };

    return (
        <div>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ width: "100%" }}>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        style={{ marginTop: 20 }}
                    >
                        <Grid item xs={12} sm={12} md={6}>
                            <Paper
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#EEEEEE",
                                    padding: "5px 0px",
                                    flexGrow: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                elevation={2}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        my: 1,
                                        height: 40,
                                        width: 40,
                                        maxHeight: { xs: 40, md: 40 },
                                        maxWidth: { xs: 40, md: 40 },
                                    }}
                                    alt="HomePage"

                                />
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#274481",
                                        fontSize: "30px",
                                    }}
                                >
                                    {/* {allStats?.data?.userCount} */}
                                </Typography>

                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Paper
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#EEEEEE",
                                    padding: "5px 0px",
                                    flexGrow: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                elevation={2}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        my: 1,
                                        height: 40,
                                        width: 40,
                                        maxHeight: { xs: 40, md: 40 },
                                        maxWidth: { xs: 40, md: 40 },
                                    }}
                                    alt="HomePagelogo"

                                />
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#274481",
                                        fontSize: "30px",
                                    }}
                                >
                                    {/* {allStats?.data?.userCount} */}
                                </Typography>

                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        style={{

                            marginTop: 20,

                        }}
                    >
                        <Grid item xs={12} sm={12} md={12}
                            style={{
                                display: 'flex',
                                // marginTop: 20,
                                flexDirection: "row",
                            }}>
                            <Paper
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    background: "#EEEEEE",
                                    // padding: "1px 0px",
                                    flexGrow: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            // elevation={2}
                            >
                                <Box

                                    sx={{
                                        my: 1,
                                        width: 40,
                                        maxWidth: { xs: 40, md: 40 },
                                    }}

                                />
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#274481",
                                        fontSize: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        // alignItems: "start",
                                    }}
                                >
                                    Buissnesses
                                    {/* {allStats?.data?.userCount} */}
                                </Typography>

                            </Paper>
                            <Grid item xs={12} sm={12} md={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: "center"
                                }}>
                                <Button
                                    sx={{

                                        fontWeight: "bold",
                                        color: "#274481",
                                        fontSize: "30px",
                                        background: "#e38226",
                                        // my: 1,
                                        width: 300,
                                        height: "auto",


                                    }}
                                    onClick={addRow}
                                >+
                                    {/* {allStats?.data?.userCount} */}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}
                        sx={{ marginTop: 0.5, paddingTop: 0.2 }}>
                        <Grid item xs={12}
                        >
                            <Paper sx={{ width: "100%", mb: 2 }}>
                                <TableContainer >
                                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                        {/* <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows?.count}
                /> */}
                                        <TableHead >
                                            <TableRow>
                                                {headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        sx={{
                                                            fontWeight: "bold",
                                                            fontSize: "14px",
                                                        }}
                                                        align={headCell.align}
                                                        padding={headCell.disablePadding ? "none" : "normal"}

                                                    >
                                                        <TableSortLabel

                                                        >
                                                            {headCell.label}

                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                            </TableRow>

                                            {/* <TableRow sx={{
                                                display: "flex",
                                                justifyContent: 'space-between'
                                            }}>


                                                <TableCell
                                                    className="form-control">
                                                    Sr
                                                </TableCell>

                                                <TableCell
                                                    className="form-control"
                                                    align="center"
                                                    sx={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Name
                                                </TableCell>

                                                <TableCell
                                                    align="center"
                                                    className="form-control"
                                                >
                                                    Flag
                                                </TableCell>
                                                <TableCell
                                                    className="form-control">
                                                    Actions
                                                </TableCell>
                                              
                                            </TableRow> */}

                                        </TableHead>

                                        <TableBody>

                                            {rowsData.map((data, index) => {
                                                const { Sr, Name, flag, Actions } = data;
                                                return (
                                                    <TableRow deleteTableRows={deleteTableRows} handleChange={handleChange} key={index}>
                                                        <TableCell >{index + 1}</TableCell>
                                                        <TableCell>
                                                            <input type="text" value={Name} onChange={(evnt) => (handleChange(index, evnt))} name="Name" alignItems="center" />
                                                        </TableCell>
                                                        <TableCell><input type="text" value={flag} onChange={(evnt) => (handleChange(index, evnt))} name="flag" className="form-control" /> </TableCell>
                                                        {/* <td><input type="text" value={salary}  onChange={(evnt)=>(handleChange(index, evnt))} name="Sr" className="form-control" /> </td> */}
                                                        <TableCell><button className="btn btn-outline-danger" onClick={() => (deleteTableRows(index))} >x</button></TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        style={{
                            marginTop: 10,
                            alignItems: "center",
                        }}
                    >
                        <Grid item xs={12} sm={9} md={6}>
                            <Paper
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#EEEEEE",
                                    padding: "2px 0px",
                                    flexGrow: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                elevation={2}
                            >
                                <Box

                                    sx={{
                                        my: 1,
                                        width: 40,
                                        maxWidth: { xs: 40, md: 40 },
                                    }}


                                />
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#274481",
                                        fontSize: "30px",
                                        alignItems: "center",
                                    }}
                                > Our Mission
                                    {/* {allStats?.data?.userCount} */}
                                </Typography>

                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <Paper
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#EEEEEE",
                                    padding: "5px 0px",
                                    flexGrow: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                elevation={2}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        my: 1,

                                        width: 40,

                                        maxWidth: { xs: 40, md: 40 },
                                    }}


                                />
                                <Input
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#274481",
                                        fontSize: "20px",
                                    }}

                                />
                                {/* {allStats?.data?.userCount} */}


                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}
                            sx={{
                                display: 'flex',
                                justifyContent: "center"
                            }}>
                            <Button
                                sx={{

                                    fontWeight: "bold",
                                    color: "#274481",
                                    fontSize: "30px",
                                    background: "#e38226",
                                    // my: 1,
                                    width: 300,
                                    height: "auto",


                                }}
                            >Upload video
                                {/* {allStats?.data?.userCount} */}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

            </Box>


            <Grid sx={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                right: 0,
            }}>

                <TableContainer sx={{
                    borderTop: "solid",
                    background: "#e38226",


                }}>
                    <TableRow sx={{
                        display: "flex",
                        justifyContent: "space-around",
                    }}>
                        <TableCell >
                            <InstagramIcon />

                        </TableCell>
                        <TableCell >
                            <FacebookIcon />
                        </TableCell>

                        <TableCell >

                            <TwitterIcon />
                        </TableCell>
                        <TableCell >

                            <ShareIcon />
                        </TableCell>
                    </TableRow>

                </TableContainer>
            </Grid>


        </div >
    )
}
export default HomePage;
