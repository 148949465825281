import {
  ADMIN_GET_VANUE_REQUEST,
  ADMIN_GET_VANUE_SUCCESS,
  ADMIN_GET_VANUE_FAIL,
  ADMIN_REMOVE_VANUE_REQUEST,
  ADMIN_REMOVE_VANUE_SUCCESS,
  ADMIN_REMOVE_VANUE_FAIL,
  ADMIN_CREATE_VANUE_REQUEST,
  ADMIN_CREATE_VANUE_SUCCESS,
  ADMIN_CREATE_VANUE_FAIL,
  ADMIN_GET_VANUE_BY_ID_REQUEST,
  ADMIN_GET_VANUE_BY_ID_SUCCESS,
  ADMIN_GET_VANUE_BY_ID_FAIL,
  ADMIN_UPDATE_VANUE_REQUEST,
  ADMIN_UPDATE_VANUE_SUCCESS,
  ADMIN_UPDATE_VANUE_FAIL,
  ADMIN_VANUE_TYPE_UPLOADIMAGE_REQUEST,
  ADMIN_VANUE_TYPE_UPLOADIMAGE_SUCCESS,
  ADMIN_VANUE_TYPE_UPLOADIMAGE_FAIL,
  ADMIN_VANUE_TYPE_UPLOADIMAGE_RESPONSE,
} from "../Types";

export const AdminVanueReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_VANUE_REQUEST:
      return { loading: true };
    case ADMIN_GET_VANUE_SUCCESS:
      return { loading: false, allVanueList: action.payload };
    case ADMIN_GET_VANUE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const CreateVanueReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CREATE_VANUE_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_VANUE_SUCCESS:
      return { loading: false, createVanue: action.payload };
    case ADMIN_CREATE_VANUE_FAIL:
      return { loading: false, createVanue: action.payload };
    default:
      return state;
  }
};

export const Admin_Vanue_UploadImage_Reducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_VANUE_TYPE_UPLOADIMAGE_REQUEST:
      return { loading: true };
    case ADMIN_VANUE_TYPE_UPLOADIMAGE_SUCCESS:
      return { loading: false, uploadVanueImage: action.payload };
    case ADMIN_VANUE_TYPE_UPLOADIMAGE_FAIL:
      return { loading: false, uploadVanueImage: action.payload };
    case ADMIN_VANUE_TYPE_UPLOADIMAGE_RESPONSE:
      return { loading: false, uploadVanueImage: null };
    default:
      return state;
  }
};
export const AdminGetVanueByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_VANUE_BY_ID_REQUEST:
      return { loading: true };
    case ADMIN_GET_VANUE_BY_ID_SUCCESS:
      return { loading: false, vanueDetail: action.payload };
    case ADMIN_GET_VANUE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const UpdateVanueReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_VANUE_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_VANUE_SUCCESS:
      return { loading: false, updateVanue: action.payload };
    case ADMIN_UPDATE_VANUE_FAIL:
      return { loading: false, updateVanue: action.payload };
    default:
      return state;
  }
};

export const RemoveVanueReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_REMOVE_VANUE_REQUEST:
      return { loading: true };
    case ADMIN_REMOVE_VANUE_SUCCESS:
      return { loading: false, removeVanue: action.payload };
    case ADMIN_REMOVE_VANUE_FAIL:
      return { loading: false, removeVanue: action.payload };
    default:
      return state;
  }
};
