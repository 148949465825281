import {
  ADMIN_FLAG_USER_REQUEST,
  ADMIN_FLAG_USER_SUCCESS,
  ADMIN_FLAG_USER_FAIL,
  ADMIN_CLEAR_FLAG_REQUEST,
  ADMIN_CLEAR_FLAG_SUCCESS,
  ADMIN_CLEAR_FLAG_FAIL,
} from "../Types";

export const AdminFlagReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_FLAG_USER_REQUEST:
      return { loading: true };
    case ADMIN_FLAG_USER_SUCCESS:
      return { loading: false, userFlag: action.payload };
    case ADMIN_FLAG_USER_FAIL:
      return { loading: false, userFlag: action.payload };
    default:
      return state;
  }
};

export const AdminClearFlagReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CLEAR_FLAG_REQUEST:
      return { loading: true };
    case ADMIN_CLEAR_FLAG_SUCCESS:
      return { loading: false, userClearFlag: action.payload };
    case ADMIN_CLEAR_FLAG_FAIL:
      return { loading: false, userClearFlag: action.payload };
    default:
      return state;
  }
};
