import {
  ADMIN_PAYOUT_REQUEST,
  ADMIN_PAYOUT_SUCCESS,
  ADMIN_PAYOUT_FAIL,
} from "../Types";

export const AdminPayoutReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_PAYOUT_REQUEST:
      return { loading: true };
    case ADMIN_PAYOUT_SUCCESS:
      return { loading: false, payoutResult: action.payload };
    case ADMIN_PAYOUT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
