import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { AdminLoginReducer } from "./Reducers/AdminLoginReducer";
import { AdminGetProfileDetailReducer } from "./Reducers/AdminGetProfileDetailReducer";
import {
  AdminProfileUpdateReducer,
  Admin_UploadProfileImage_Reducer,
} from "./Reducers/AdminProfileUpdateReducer";
import {
  AdminStatsReducer,
  LessonsBySportsReducer,
  EarningBySportsReducer,
} from "./Reducers/AdminStatsReducer";
import { AdminStudentsReducer } from "./Reducers/AdminStudentsReducer";
import {
  AdminDocumentVerifyReducer,
  AdminParentsReducer,
} from "./Reducers/AdminParentsReducer";
import {
  AdminCoachesReducer,
  AdminCoachHeaderReducer,
  AdminCoachBookingListReducer,
} from "./Reducers/AdminCoachesReducer";
import {
  AdminConfirmPasswordReducer,
  AdminForgotOtpReducer,
  AdminForgotPasswordReducer,
} from "./Reducers/AdminForgetPasswordReducers";
import {
  AdminBookingReducer,
  AdminBookingDetailsReducer,
} from "./Reducers/AdminBookingReducer";
import thunk from "redux-thunk";
import {
  Admin_SportsTypes_UploadImage_Reducer,
  CreateSportsReducer,
  RemoveSportsReducer,
  UpdateSportsReducer,
} from "./Reducers/SportsTypesReducer";
import {
  AdminGettingSportsReducer,
  AdminGettingSportsByIdReducer,
} from "./Reducers/AdminGettingReducer";
import {
  AdminVanueReducer,
  RemoveVanueReducer,
  CreateVanueReducer,
  AdminGetVanueByIdReducer,
  UpdateVanueReducer,
  Admin_Vanue_UploadImage_Reducer,
} from "./Reducers/VanueReducer";
import { AdminPayoutReducer } from "./Reducers/AdminPayoutReducer";
import {
  AdminFlagReducer,
  AdminClearFlagReducer,
} from "./Reducers/AdminFlagReducer";
import {
  AdminGetAllContentReducer,
  AdminUpdateContentReducer,
} from "./Reducers/ContentManagementReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  adminLogin: AdminLoginReducer,
  getProfileDetail: AdminGetProfileDetailReducer,
  profileUpdate: AdminProfileUpdateReducer,
  uploadProfile: Admin_UploadProfileImage_Reducer,
  allStats: AdminStatsReducer,
  lessonsBySports: LessonsBySportsReducer,
  earningBySports: EarningBySportsReducer,
  allStudents: AdminStudentsReducer,
  allParents: AdminParentsReducer,
  allVanueList: AdminVanueReducer,
  vanueDetail: AdminGetVanueByIdReducer,
  removeVanue: RemoveVanueReducer,
  createVanue: CreateVanueReducer,
  updateVanue: UpdateVanueReducer,
  uploadVanueImage: Admin_Vanue_UploadImage_Reducer,
  allCoaches: AdminCoachesReducer,
  coachHeader: AdminCoachHeaderReducer,
  coachBookingsByid: AdminCoachBookingListReducer,
  forgotPassword: AdminForgotPasswordReducer,
  forgotOtp: AdminForgotOtpReducer,
  confirmPassword: AdminConfirmPasswordReducer,
  allBooking: AdminBookingReducer,
  bookingDetails: AdminBookingDetailsReducer,
  CreateSports: CreateSportsReducer,
  SportsTypes_UploadImage: Admin_SportsTypes_UploadImage_Reducer,
  removeSports: RemoveSportsReducer,
  updateSports: UpdateSportsReducer,
  GettingSports: AdminGettingSportsReducer,
  sportsDetails: AdminGettingSportsByIdReducer,
  documentVerify: AdminDocumentVerifyReducer,
  payoutResult: AdminPayoutReducer,
  userFlag: AdminFlagReducer,
  userClearFlag: AdminClearFlagReducer,
  getContent: AdminGetAllContentReducer,
  updateContent: AdminUpdateContentReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
