import {
  ADMIN_GET_STUDENTS_REQUEST,
  ADMIN_GET_STUDENTS_SUCCESS,
  ADMIN_GET_STUDENTS_FAIL,
} from "../Types";

export const AdminStudentsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_STUDENTS_REQUEST:
      return { loading: true };
    case ADMIN_GET_STUDENTS_SUCCESS:
      return { loading: false, allStudents: action.payload };
    case ADMIN_GET_STUDENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
