import {
  ADMIN_UPDATE_PROFILE_REQUEST,
  ADMIN_UPDATE_PROFILE_SUCCESS,
  ADMIN_UPDATE_PROFILE_FAIL,
  ADMIN_UPLOADIMAGE_REQUEST,
  ADMIN_UPLOADIMAGE_SUCCESS,
  ADMIN_UPLOADIMAGE_FAIL,
  ADMIN_UPLOADIMAGE_RESPONSE,
} from "../Types";

export const AdminProfileUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_PROFILE_SUCCESS:
      return { loading: false, updateProfileDetail: action.payload };
    case ADMIN_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const Admin_UploadProfileImage_Reducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPLOADIMAGE_REQUEST:
      return { loading: true };
    case ADMIN_UPLOADIMAGE_SUCCESS:
      return { loading: false, uploadProfile: action.payload };
    case ADMIN_UPLOADIMAGE_FAIL:
      return { loading: false, uploadProfile: action.payload };
    case ADMIN_UPLOADIMAGE_RESPONSE:
      return { loading: false, uploadProfile: null };
    default:
      return state;
  }
};
