import {
  ADMIN_GET_SPORTS_REQUEST,
  ADMIN_GET_SPORTS_SUCCESS,
  ADMIN_GET_SPORTS_FAIL,
  ADMIN_GET_SPORTS_BY_ID_REQUEST,
  ADMIN_GET_SPORTS_BY_ID_SUCCESS,
  ADMIN_GET_SPORTS_BY_ID_FAIL,
} from "../Types";
export const AdminGettingSportsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_SPORTS_REQUEST:
      return { loading: true };
    case ADMIN_GET_SPORTS_SUCCESS:
      return { loading: false, sportsList: action.payload };
    case ADMIN_GET_SPORTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const AdminGettingSportsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_SPORTS_BY_ID_REQUEST:
      return { loading: true };
    case ADMIN_GET_SPORTS_BY_ID_SUCCESS:
      return { loading: false, sportsDetail: action.payload };
    case ADMIN_GET_SPORTS_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
