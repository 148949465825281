import {
  ADMIN_GET_CONTENT_REQUEST,
  ADMIN_GET_CONTENT_SUCCESS,
  ADMIN_GET_CONTENT_FAIL,
  ADMIN_UPDATE_CONTENT_REQUEST,
  ADMIN_UPDATE_CONTENT_SUCCESS,
  ADMIN_UPDATE_CONTENT_FAIL,
} from "../Types";

export const AdminGetAllContentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_CONTENT_REQUEST:
      return { loading: true };
    case ADMIN_GET_CONTENT_SUCCESS:
      return { loading: false, getContent: action.payload };
    case ADMIN_GET_CONTENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const AdminUpdateContentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_CONTENT_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_CONTENT_SUCCESS:
      return { loading: false, updateContent: action.payload };
    case ADMIN_UPDATE_CONTENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
