import {
  ADMIN_GET_BOOKING_REQUEST,
  ADMIN_GET_BOOKING_SUCCESS,
  ADMIN_GET_BOOKING_FAIL,
  ADMIN_GET_BOOKING_DETAIL_REQUEST,
  ADMIN_GET_BOOKING_DETAIL_SUCCESS,
  ADMIN_GET_BOOKING_DETAIL_FAIL,
} from "../Types";

export const AdminBookingReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_BOOKING_REQUEST:
      return { loading: true };
    case ADMIN_GET_BOOKING_SUCCESS:
      return { loading: false, allBooking: action.payload };
    case ADMIN_GET_BOOKING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const AdminBookingDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_BOOKING_DETAIL_REQUEST:
      return { loading: true };
    case ADMIN_GET_BOOKING_DETAIL_SUCCESS:
      return { loading: false, bookingDetails: action.payload };
    case ADMIN_GET_BOOKING_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
