import {
  ADMIN_CREATE_SPORTS_REQUEST,
  ADMIN_CREATE_SPORTS_SUCCESS,
  ADMIN_CREATE_SPORTS_FAIL,
  ADMIN_SPORTS_TYPE_UPLOADIMAGE_REQUEST,
  ADMIN_SPORTS_TYPE_UPLOADIMAGE_SUCCESS,
  ADMIN_SPORTS_TYPE_UPLOADIMAGE_FAIL,
  ADMIN_SPORTS_TYPE_UPLOADIMAGE_RESPONSE,
  ADMIN_REMOVE_SPORTS_REQUEST,
  ADMIN_REMOVE_SPORTS_SUCCESS,
  ADMIN_REMOVE_SPORTS_FAIL,
  ADMIN_UPDATE_SPORTS_REQUEST,
  ADMIN_UPDATE_SPORTS_SUCCESS,
  ADMIN_UPDATE_SPORTS_FAIL,
} from "../Types";

export const CreateSportsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CREATE_SPORTS_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_SPORTS_SUCCESS:
      return { loading: false, createSports: action.payload };
    case ADMIN_CREATE_SPORTS_FAIL:
      return { loading: false, createSports: action.payload };
    default:
      return state;
  }
};

export const Admin_SportsTypes_UploadImage_Reducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SPORTS_TYPE_UPLOADIMAGE_REQUEST:
      return { loading: true };
    case ADMIN_SPORTS_TYPE_UPLOADIMAGE_SUCCESS:
      return { loading: false, uploadImage: action.payload };
    case ADMIN_SPORTS_TYPE_UPLOADIMAGE_FAIL:
      return { loading: false, uploadImage: action.payload };
    case ADMIN_SPORTS_TYPE_UPLOADIMAGE_RESPONSE:
      return { loading: false, uploadImage: null };
    default:
      return state;
  }
};

export const RemoveSportsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_REMOVE_SPORTS_REQUEST:
      return { loading: true };
    case ADMIN_REMOVE_SPORTS_SUCCESS:
      return { loading: false, removeSports: action.payload };
    case ADMIN_REMOVE_SPORTS_FAIL:
      return { loading: false, removeSports: action.payload };
    default:
      return state;
  }
};

export const UpdateSportsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_SPORTS_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_SPORTS_SUCCESS:
      return { loading: false, updateSports: action.payload };
    case ADMIN_UPDATE_SPORTS_FAIL:
      return { loading: false, updateSports: action.payload };
    default:
      return state;
  }
};
